<template>
	<div class="footer">
		<span class="footer__mark">Luis Quintero&#174; 2023</span>
	</div>
</template>

<script setup></script>

<style scoped>
.footer {
	background: black;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80px;
}

.footer__mark {
	color: white;
	font-size: 13px;
}
</style>
